import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { setRadius } from "src/member/redux/slices/userProfileDetails";
import NotFound from "src/pages/not-found";
import { hideSnackbar } from "src/redux/slices/snackbar";
import { useAppSelector } from "src/redux/store";
import Snackbar from "src/shared/components/common/Snackbar";

const AdminAppRouter = React.lazy(() => import("src/admin/router/AppRouter"));
const MemberAppRouter = React.lazy(() => import("src/member/router/AppRouter"));

const AppRouter = () => {
  const isAdmin = process.env.REACT_APP_IS_ADMIN === "true";
  const snackbarData = useAppSelector((state) => state?.root?.snackbarStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setRadius({
        name: "memberRadius",
        value: 20,
      }),
    );
  }, []);

  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={isAdmin ? <AdminAppRouter /> : <MemberAppRouter />}
        />

        <Route path="*" element={<NotFound />} />
      </Routes>

      <Snackbar
        open={!!(snackbarData.showSnackbar && snackbarData.id === "APP")}
        message={snackbarData.message}
        severity={snackbarData.severity}
        duration={snackbarData.duration}
        resetSnackbar={() => dispatch(hideSnackbar())}
      />
    </>
  );
};

export default AppRouter;
