import { removeFavouriteType } from "src/member/components/common/cards/OfferCard";
import { updateNotificationActionsType } from "src/member/components/common/navbar/Notification";
import { ChangeEmailPayload } from "src/member/components/feature/ChangeEmail";
import { forgetPinFormData } from "src/member/components/feature/ForgetPin";
import { reportOfferData } from "src/member/components/feature/ReportOffer";
import { ManageTokenData } from "src/member/components/feature/authentication/Password";
import { currentPinFormType } from "src/member/components/feature/changePin/CurrentPin";
import { newPinFormType } from "src/member/components/feature/changePin/NewPin";
import { feedbackDetailForm } from "src/member/components/feature/footer/Feedback";
import { GiftTransaction } from "src/member/components/feature/giftsInteraction/sendingGifts/userDetail";
import { cancelMembershipAPIData } from "src/member/components/feature/membership/cancelMembership/DetailedReason";
import { emailChangeType } from "src/member/pages/profile-email-change";
import { BitsType, VerifyCurrentPassword } from "src/member/types/api";
import { UserProfileResponse } from "src/member/types/response/userProfile";
import dataProvider from "src/shared/dataProvider";

let fetcher = dataProvider("memberInstance");

export const getViewInfo = async (id: string) => {
  return await fetcher.get<UserProfileResponse>(`/user/${id}`);
};

export const updateProfileInfo = async (id: string, data: any) => {
  return await fetcher.put(`/user/${id}`, data);
};

export const setCurrentPin = async (data: currentPinFormType) => {
  return await fetcher.post("/user/verify-pin", data);
};

export const changePin = async (
  data: newPinFormType,
  headers: { "x-pin-token": string },
) => {
  return await fetcher.post("/user/change-pin", data, {}, "v1", headers);
};

export const forgetPin = async (data: forgetPinFormData) => {
  return await fetcher.post("/user/forgot-pin", data);
};

export const changeEmail = async (data: ChangeEmailPayload) => {
  return await fetcher.post("/user/email-change-request", data);
};

export const verifyEmailChange = async (
  data: {},
  headers: { "x-verify-email-token": string },
) => {
  return await fetcher.post(
    "/user/verify-email-change",
    data,
    {},
    "v1",
    headers,
  );
};

export const getOffer = async (
  id: string,
  offerType: string,
  offerId?: string,
  sortBy?: string,
) => {
  return await fetcher.get(`/offer/${id}`, {
    offerType: offerType,
    offerId: offerId,
    sortBy: sortBy,
  });
};

export const getFavouriteOffer = async (
  id: string,
  // offerType: string,
  favourite: string,
  limit?: number,
  offset?: number,
) => {
  return await fetcher.get(`/offer/${id}`, {
    favourite,
    limit,
    offset,
  });
};

export const getOfferDetails = async (id: string) => {
  return await fetcher.get(`/offer/details/${id}`);
};

export const getReportReason = async () => {
  return await fetcher.get(`/offer/report-reasons`);
};

// TODO fix names of all functions
export const pausedOffer = async (data: any, headers?: any) => {
  return await fetcher.patch(
    `/offer`,
    { ...data, user: "MEMBER" },
    {},
    "v1",
    headers,
  );
};

export const cancelMembership = async (data: cancelMembershipAPIData) => {
  return await fetcher.post("/subscription/cancel-subscription", data);
};

export const reportOfferDetail = async (data: reportOfferData) => {
  return await fetcher.post("/offer/report", data);
};

export const changePassword = async (data: { password: string }, headers) => {
  return await fetcher.post("/user/change-password", data, {}, "v1", headers);
};

export const verifyCurrentPassword = async (data: VerifyCurrentPassword) => {
  return await fetcher.post(`/user/verify-password`, data);
};

export const removeFromFavourite = async (data: removeFavouriteType) => {
  return await fetcher.post(`/offer/my-favourite`, data);
};

export const getOfferData = async (id: string) => {
  return await fetcher.get(`/offer/details/${id}`);
};

export const getReferralData = async (id: string) => {
  return await fetcher.get(`/referral/my-code?inviterId=${id}`);
};

export const getReferralTableData = async (id: string, order?: any) => {
  return await fetcher.get(`/referral/?inviterId=${id}`, order);
};
export const getTransactionHistory = async (queryParams: any) => {
  return await fetcher.get(`/wallet/transaction-history`, queryParams);
};
export const getWalletDetails = async () => {
  return await fetcher.get(`/wallet`);
};
export const getWalletDetailsById = async (id: string) => {
  return await fetcher.get(`/wallet/transaction/${id}`);
};

export const loadBits = async (data: BitsType) => {
  return await fetcher.post("/wallet/load-bits", data);
};
export const changeEmailRequest = async (data: emailChangeType) => {
  return await fetcher.post(`/user/email-change-request`, data);
};

export const userSignUp = async (data: any) => {
  return await fetcher.post(`/user/signup`, data);
};

export const approveEmailChange = async (
  data: {},
  headers: {
    "x-verify-email-token": string;
  },
) => {
  return await fetcher.post(
    `/user/approve-email-change`,
    data,
    {},
    "v1",
    headers,
  );
};
export const declineEmailChange = async (
  data: {},
  headers: {
    "x-verify-email-token": string;
  },
) => {
  return await fetcher.post(
    `/user/decline-email-change`,
    data,
    {},
    "v1",
    headers,
  );
};

export const emailChange = async (
  data: {},
  headers: {
    "x-verify-email-token": string;
  },
) => {
  return await fetcher.post(`/user/email-change`, data, {}, "v1", headers);
};

// export const searchFilter = async (queryParams, search, data: {}) => {
//   return await fetcher.get(
//     `/offer/search-offer?${queryParams}&${search}`,
//     data,
//   );
// };
export const searchOffers = async (queryParams) => {
  return await fetcher.get(`/offer/search-offer`, queryParams);
};

export const getExploreData = async () => {
  return await fetcher.get(`/offer/home-page`);
};

export const getBarterRecord = async (id: string, queryParams: any) => {
  return await fetcher.get(`/purchase/barter-record/user/${id}`, queryParams);
};

export const getBarterRequests = async (id: string, queryParams) => {
  return await fetcher.get(`/purchase/barter-request/user/${id}`, queryParams);
};

export const getTransactionRequestInfo = async (id: string) => {
  return await fetcher.get(`/purchase/barter-request/` + id);
};

export const postTransactionRequest = async (data) => {
  return await fetcher.post(`/purchase/barter-request/confirmation`, data);
};

export const verifyWalletNumber = async (walletNumber: string) => {
  return await fetcher.get(`/wallet/` + walletNumber);
};
export const getViewStatement = async (dateLimit, isDownload) => {
  return await fetcher.get(
    `/wallet/statement?download=${isDownload}`,
    dateLimit,
    // "v1",
    // "application/pdf",
  );
};

export const createBarterRequest = async (data) => {
  return await fetcher.post(`/purchase/barter-request`, data);
};

export const getMemberProfileById = async (id: string) => {
  return await fetcher.get(`/user/${id}?raw=explore`);
};

export const getMemberByLocation = async ({
  radius,
  loginId,
  queryParams,
  search,
}) => {
  return await fetcher.get(
    `/user/find-members/loginId?radius=${radius}&loginId=${loginId}`,
    { ...queryParams, search },
  );
};

export const createBarterRefund = async (data: {
  requestId: string;
  refundAmount: number;
  description?: string;
  pin: string;
}) => {
  return await fetcher.post(`/purchase/barter-refund`, data);
};

export const barterRefundHistory = async (requestId: string) => {
  return await fetcher.get(`/purchase/refund-history/${requestId}`);
};

export const getStatementDuration = async () => {
  return await fetcher.get(`/wallet/statement?limit=2&offset=0&duration=true`);
};

export const setUserLocationApi = async (data) => {
  return await fetcher.post("/user/location-setup", data);
};

export const manageToken = async (data: ManageTokenData) => {
  return await fetcher.post("/notify/manage-token", data);
};

export const notificationToggle = async (data: any) => {
  return await fetcher.patch(`/user/notification/update-setting`, data);
};

export const getNotificationToggle = async () => {
  return await fetcher.get(`/user/notification/setting`);
};

export const getNotification = async (
  userId: string,
  queryParams?: { count: boolean },
) => {
  return await fetcher.get(`/notify?userId=${userId}`, queryParams);
};

export const deleteReviewData = async (id: string) => {
  return await fetcher.delete(`/review/${id}`);
};

export const notificationActions = async (
  data: updateNotificationActionsType,
) => {
  return await fetcher.patch(`/notify`, data);
};

export const editReviewData = async (data: any) => {
  return await fetcher.patch(`/review`, data);
};

export const getSearchOfferSuggestion = async (queryParams) => {
  return await fetcher.get(`/offer/suggestions`, queryParams);
};

export const getValidateEmail = async (data: any) => {
  return await fetcher.post(`/gift/validate-email`, data);
};

export const getGiftHistory = async (data: string, queryParams: any) => {
  return await fetcher.get(`/gift/history?userId=${data}`, queryParams);
};

export const sendGiftDetail = async (data: GiftTransaction) => {
  return await fetcher.post("/gift", data);
};
export const getOfferFilters = async (queryParams: any) => {
  return await fetcher.get("/offer/filter", queryParams);
};

export const giftClaim = async (data: any) => {
  return await fetcher.post("/gift/claim", data);
};

export const feedbackDetails = async (data: feedbackDetailForm) => {
  return await fetcher.post("/feedback", data);
};

export const getGiftData = async (id: string) => {
  return await fetcher.get(`/gift/${id}`);
};

export const getOverAllRating = async (id: string) => {
  return await fetcher.get(`/review/summary?userId=${id}`);
};

export const getAcceptGiftData = async (id: string, queryParams) => {
  return await fetcher.get(`/gift?userId=${id}`, queryParams);
};

export const getGivenReceivedRatingData = async (
  id: string,
  isGiven: boolean,
) => {
  return await fetcher.get(`/review?userId=${id}`, {
    isGiven,
  });
};
export const getReceivedRatingData = async (id: string) => {
  return await fetcher.get(`/review?userId=${id}&isGiven=false`);
};

export const replyReview = async (data: any) => {
  return await fetcher.post("/review/reply", data);
};

export const postReview = async (data: any) => {
  return await fetcher.post("/review", data);
};

export const likeDislikeDetail = async (data: any) => {
  return await fetcher.post("/review/like-dislike", data);
};

export const reportReviewData = async (data: any) => {
  return await fetcher.post("/review/report", data);
};

export const getStatsInfo = async () => {
  return await fetcher.get(`/analytics/stats`);
};

export const getLikeDislikeData = async (
  id: string,
  likeOrDislike: string,
  offset: number,
  limit: number,
) => {
  return await fetcher.get(
    `/review/${id}/like-dislike-history?likeOrDislike=${likeOrDislike}&offset=${offset}&limit=${limit}`,
  );
};

export const getReplyReview = async (id: string) => {
  return await fetcher.get(`/review/${id}`);
};

export const getAllReplyByReviewId = async (id: string) => {
  return await fetcher.get(`/review/${id}/reply`);
};

export const getEditReply = async (id: string) => {
  return await fetcher.get(`/review/reply/${id}`);
};
export const renewSubscription = async () => {
  return await fetcher.post(`/subscription/renew-subscription`, {});
};

export const getMemberByLatLong = async (
  latlong,
  type = "CUSTOMER_BUSINESS",
) => {
  return await fetcher.get(
    `/user/nearby-pins?type=${type}&latitude=${latlong?.latitude}&longitude=${latlong?.longitude}`,
  );
};

export const getStripeCustomerid = async (headers, userId = "") => {
  const url = userId
    ? `/subscription/customer?userId=${userId}`
    : `/subscription/customer`;

  return await fetcher.get(url, {}, "v1", headers);
};

export const checkWebRestrictions = async () => {
  return await fetcher.get("/maintenance/check");
};

export const unLockModules = async (data) => {
  return await fetcher.post(`/maintenance/unlock-system`, data);
};
